import { useState, useEffect, useRef } from "react";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Progress,
} from "reactstrap";
import InputFieldWithError from "../../InputFieldWithError";
import { createValidationPasswordSchema } from "../../../utils/createValidationSchema";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner";

const ResetPasswordForm = () => {
  const formRef = useRef();
  const theme = useSelector((state) => state?.theme?.theme);
  const [step, setStep] = useState(1);
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [newPasswordAgainError, setNewPasswordAgainError] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const [isSuccess, setIsSuccess] = useState("");

  const [resetCode, setresetCode] = useState();

  const [countdown, setCountdown] = useState(240);

  useEffect(() => {
    if ((step === 2 || step === 3) && countdown > 0) {
      const timer = setInterval(() => {
        if (countdown > 0) {
          setCountdown(countdown - 1);
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [countdown, step]);

  useEffect(() => {
    if (countdown === 0) {
      setStep(4);
    }
  }, [countdown]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "code":
        setCode(value);
        break;
      case "newPasswordError":
        setNewPasswordError(value);
        break;
      default:
        break;
    }
  };
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const prepareCodes = (e) => {
    setIsInvalidCode(false);
    e.preventDefault();
    if (resetCode == code) nextStep();
    else setIsInvalidCode(true);
  };

  const resetCodeSender = (e) => {
    e.preventDefault();
  };

  const resetPasswordHandler = (e) => {
    e.preventDefault();
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <p> adresine bir onay kodu gönderilecektir.</p>

            <Button
              color={theme === "dark" ? "primary" : "purple"}
              onClick={resetCodeSender}
              size="sm"
            >
              Kod Gönder
            </Button>
            <div className="text-danger mt-2">İşlem gerçekleştirilemedi.</div>
          </div>
        );
      case 2:
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              nextStep();
            }}
          >
            <Progress value={countdown} max={240} color="purple">
              {countdown} saniye
            </Progress>

            <FormGroup className="mt-2">
              <Label for="code">Onay Kodu</Label>
              <Input
                type="text"
                name="code"
                id="code"
                placeholder="E-mail adresinize gelen onay kodunu giriniz..."
                value={code}
                onChange={handleChange}
              />
            </FormGroup>
            <Button
              color={theme === "dark" ? "primary" : "purple"}
              size="sm"
              onClick={prepareCodes}
            >
              Kodu Doğrula
            </Button>

            {isInvalidCode && (
              <div className="text-danger mt-2">Hatalı bir kod girdiniz.</div>
            )}
          </form>
        );
      case 3:
        return (
          <form onSubmit={resetPasswordHandler} ref={formRef}>
            <Progress
              value={countdown}
              max={240}
              color="purple"
              className="mb-3"
            >
              {countdown} saniye
            </Progress>

            <FormGroup>
              <InputFieldWithError
                value={newPassword}
                setValue={setNewPassword}
                label="Yeni Şifreniz"
                name="newPassword"
                id="newPassword"
                setFormErrors={setNewPasswordError}
                formErrors={newPasswordError}
                args={{
                  placeholder: "Lütfen yeni şifrenizi giriniz...",
                  type: "password",
                  autoComplete: "new-password",
                }}
                validationSchema={createValidationPasswordSchema(
                  "newPassword",
                  true,
                  6,
                  10
                )}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="newPasswordAgain">Yeni Şifreniz Tekrar</Label>
              <Input
                type="password"
                name="newPasswordAgain"
                id="newPasswordAgain"
                invalid={newPasswordAgainError !== ""}
                placeholder="Lütfen yeni şifrenizi tekrar giriniz..."
              />
              <FormFeedback invalid>{newPasswordAgainError}</FormFeedback>
            </FormGroup>

            <Button
              color={theme === "dark" ? "primary" : "purple"}
              size="sm"
              type="submit"
              onSubmit={resetPasswordHandler}
            >
              Şifreyi Yenile
            </Button>
            <div className="text-danger mt-2">İşlem gerçekleştirilemedi.</div>
            <div className="text-success mt-2">İşlem gerçekleştirildi.</div>
          </form>
        );
      case 4:
        return (
          <div>
            <p className="text-warning">İşlem zaman aşımına uğradı.</p>
          </div>
        );
      default:
        return null;
    }
  };

  return <div>{renderStep()}</div>;
};

export default ResetPasswordForm;
