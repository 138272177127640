import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Container, Card, CardTitle, CardBody, Row, Table } from "reactstrap";
import Layout from "../../Layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import alertify from "alertifyjs";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhotoUploader from "../../PhotoUploader/PhotoUploader";
const endpoint = "contact";

function FormIndexContact() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();
  const [preview, setPreview] = useState("");

  const [form, setForm] = useState({
    adres: "",
    adres_en: "",
    tel1: "",
    tel2: "",
    mail: "",
    saat: "",
    saat_en: "",
    img: "",
  });

  const [validation, setValidation] = useState({
    adres: "",
    adres_en: "",
    tel1: "",
    tel2: "",
    mail: "",
    saat: "",
    saat_en: "",
    img: "",
  });
  useEffect(() => {
    if (location.state) {
      const { product } = location.state;
      setForm(product);
      setPreview(
        product.img
          ? `https://idare.konakglobal.com/uploads/${product.img}`
          : ""
      );
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state)
      Object.entries(location.state.product).forEach(([key, value]) => {
        onChangeHandler(key, value);
        setValidation((prevState) => ({ ...prevState }));
      });
  }, []);
  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    let formData = new FormData();
    formData.append("adres", form.adres);
    formData.append("adres_en", form.adres_en);
    formData.append("saat", form.saat);
    formData.append("tel1", form.tel1);
    formData.append("tel2", form.tel2);
    formData.append("saat_en", form.saat_en);
    formData.append("mail", form.mail);
    formData.append("img", form.img);

    try {
      const rst = await formApi.addPage(formData);
      toast.success(
        "İlgili alan eklendi..",
        ((window.location.href = "/contact"), 5000),
        2.5
      );
    } catch (e) {
      toast.error("İlgili alan eklenemedi..", 2.5);
    }
  };
  const onUpdateHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      toast.error("Düzenlemeler yapılamadı..", 2.5);

      return;
    } else {
      let formData = new FormData();
      formData.append("_id", form._id);
      formData.append("adres", form.adres);
      formData.append("adres_en", form.adres_en);
      formData.append("saat", form.saat);
      formData.append("tel1", form.tel1);
      formData.append("tel2", form.tel2);
      formData.append("saat_en", form.saat_en);
      formData.append("mail", form.mail);
      formData.append("img", form.img);

      try {
        const rst = await formApi.updatePage(formData);
        toast.success(
          "Düzenlemeler yapıldı..",
          ((window.location.href = "/contact"), 1000),
          2.5
        );
      } catch (e) {
        toast.error("Düzenlemeler yapılamadı..", 1000);
      }
    }
  };

  const handlePhotoUpload = (index, file, lang) => {
    console.log("Selected file:", file);

    setForm((prevState) => ({
      ...prevState,
      img: file,
    }));

    setPreview(URL.createObjectURL(file));
  };

  return (
    <Layout>
      <ToastContainer />
      <main className="home">
        <Container>
          <div className="h1">İletişim Sayfası</div>
        </Container>

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <br />
                  <div className="container mt-3 pb-5">
                    <h4>İletişim Sayfası Paneli</h4>
                    <hr />

                    <Form onSubmit={onSubmitHandler}>
                      <br />
                      <Label>
                        <b>İletişim Banner</b>
                      </Label>
                      <div
                        style={{
                          marginBottom: "25px",
                          border: "1px solid #ccc",
                          padding: "20px",
                          borderRadius: "5px",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Label style={{ marginRight: "10px" }}></Label>
                          <PhotoUploader
                            index={0}
                            onPhotoUpload={handlePhotoUpload}
                            initialPreview={
                              preview ||
                              (form.img
                                ? `https://idare.konakglobal.com/uploads/${form.img}`
                                : "")
                            }
                          />
                        </div>
                        <div style={{ width: "30%" }}>
                          {preview && (
                            <img
                              src={preview}
                              alt="Preview"
                              style={{ marginTop: "10px", maxWidth: "100%" }}
                            />
                          )}
                        </div>
                        <br />{" "}
                      </div>
                      <Label for="adres">
                        <b>Adres (tr)</b>
                      </Label>
                      <CKEditor
                        name="adres"
                        editor={ClassicEditor}
                        data={form.adres}
                        onReady={(editor) => {
                          if (form.adres !== "") editor.setData(form.adres);
                        }}
                        onChange={(event, editor) =>
                          onChangeHandler("adres", editor.getData())
                        }
                      />
                      {validation.adres === "F" && (
                        <div className="val-message">
                          Bu alan boş bırakılamaz.
                        </div>
                      )}

                      <br />

                      <Label for="adres_en">
                        <b>Adres (en)</b>
                      </Label>
                      <CKEditor
                        name="adres_en"
                        editor={ClassicEditor}
                        data={form.adres_en}
                        onReady={(editor) => {
                          if (form.adres_en !== "")
                            editor.setData(form.adres_en);
                        }}
                        onChange={(event, editor) =>
                          onChangeHandler("adres_en", editor.getData())
                        }
                      />
                      {validation.adres_en === "F" && (
                        <div className="val-message">
                          Bu alan boş bırakılamaz.
                        </div>
                      )}
                      <br />
                      <FormGroup>
                        <Label for="tel1">
                          <b>Telefon 1 </b>
                        </Label>
                        <Input
                          id="tel1"
                          name="tel1"
                          placeholder="Lütfen telefon numarası giriniz."
                          type="text"
                          onChange={(e) =>
                            onChangeHandler(e.target.name, e.target.value)
                          }
                          value={form.tel1}
                          valid={validation.tel1 === "T"}
                          invalid={validation.tel1 === "F"}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="tel2">
                          <b>Telefon 2 </b>
                        </Label>
                        <Input
                          id="tel2"
                          name="tel2"
                          placeholder="Lütfen telefon numarası giriniz."
                          type="text"
                          onChange={(e) =>
                            onChangeHandler(e.target.name, e.target.value)
                          }
                          value={form.tel2}
                          valid={validation.tel2 === "T"}
                          invalid={validation.tel2 === "F"}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="mail">
                          <b>Mail </b>
                        </Label>
                        <Input
                          id="mail"
                          name="mail"
                          placeholder="Lütfen mail adresinizi giriniz."
                          type="text"
                          onChange={(e) =>
                            onChangeHandler(e.target.name, e.target.value)
                          }
                          value={form.mail}
                          valid={validation.mail === "T"}
                          invalid={validation.mail === "F"}
                        />
                      </FormGroup>
                     
                      <br />
                      <Label for="saat">
                        <b>Saat (tr)</b>
                      </Label>
                      <CKEditor
                        name="saat"
                        data={form.saat}
                        editor={ClassicEditor}
                        onReady={(editor) => {
                          if (form.saat !== "") editor.setData(form.saat);
                        }}
                        onChange={(event, editor) =>
                          onChangeHandler("saat", editor.getData())
                        }
                      />
                      {validation.saat === "F" && (
                        <div className="val-message">
                          Bu alan boş bırakılamaz.
                        </div>
                      )}

                      <br />
                      <Label for="saat_en">
                        <b>Saat (en)</b>
                      </Label>
                      <CKEditor
                        name="saat_en"
                        data={form.saat_en}
                        editor={ClassicEditor}
                        onReady={(editor) => {
                          if (form.saat_en !== "") editor.setData(form.saat_en);
                        }}
                        onChange={(event, editor) =>
                          onChangeHandler("saat_en", editor.getData())
                        }
                      />
                      {validation.saat_en === "F" && (
                        <div className="val-message">
                          Bu alan boş bırakılamaz.
                        </div>
                      )}

                      <br />

                      {"  "}
                      <Button
                        type="submit"
                        style={{
                          background: "white",
                          color: "black",
                        }}
                        onClick={(e) => onUpdateHandler(e)}
                      >
                        Güncelle
                      </Button>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default FormIndexContact;
