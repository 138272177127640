import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Container, Card, CardTitle, CardBody, Row, Table } from "reactstrap";
import Layout from "../../Layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import alertify from "alertifyjs";
import VideoUploader from "../../VideoUploader";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const endpoint = "homepage";

function FormIndexHomepage() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();
  const [titleContent, setTitleContent] = useState("");
  const [titleEnContent, setTitleEnContent] = useState("");
  const [form, setForm] = useState({
    title: "",
    title_en: "",
    video: "",
    video_en:"",
    video_mobil: "",
    video_mobil_en:""
  });

  const [validation, setValidation] = useState({
    title: "",
    title_en: "",
    video: "",
    video_en:"",
    video_mobil: "",
    video_mobil_en:""
  });

  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    let formData = new FormData();
    formData.append("video", form.video);
    formData.append("video_en", form.video_en);
    formData.append("video_mobil", form.video_mobil);
    formData.append("video_mobil_en", form.video_mobil_en);
    formData.append("title", form.title);
    formData.append("title_en", form.title_en);

    try {
      const rst = await formApi.addPage(formData);
      alertify.success(
        "İLgi alan eklendi..",
        ((window.location.href = "/home-main"), 5000),
        2.5
      );
    } catch (e) {
      alertify.error("İligli alan eklenemedi..", 2.5);
    }
  };
  const onUpdateHandler = async (e) => {
    e.preventDefault();
    console.log("Form verileri:", form); 

    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      alertify.error("Düzenlemeler yapılamadı..", 2.5);

      return;
    } else {
      let formData = new FormData();
      formData.append("_id", form._id);
      formData.append("video", form.video);
      formData.append("video_en", form.video_en);
          formData.append("video_mobil", form.video_mobil);
    formData.append("video_mobil_en", form.video_mobil_en);
      formData.append("title", form.title);
      formData.append("title_en", form.title_en);

      try {
        const rst = await formApi.updatePage(formData);
        alertify.success(
          "Düzenlemeler yapıldı..",
          ((window.location.href = "/home-main"), 1000),
          2.5
        );
      } catch (e) {
        alertify.error("Düzenlemeler yapılamadı..", 1000);
      }
    }
  };
  useEffect(() => {
    if (location.state)
      Object.entries(location.state.product).forEach(([key, value]) => {
        onChangeHandler(key, value);
        setValidation((prevState) => ({ ...prevState, video: "T" ,video_en:"T", video_mobil: "T" ,video_mobil_en:"T"}));
        if (key === "title") {
          setTitleContent(value);
        } else if (key === "title_en") {
          setTitleEnContent(value);
        }  });
  }, []);

  const onVideoSelect = (videoFile) => {
    setForm((prevState) => ({ ...prevState, ["video"]: videoFile }));
  };



  const onVideoSelectEn = (videoFile) => {
    setForm((prevState) => ({ ...prevState, ["video_en"]: videoFile }));
  };

  const onVideoSelectMobil = (videoFile) => {
    setForm((prevState) => ({ ...prevState, ["video_mobil"]: videoFile }));
  };



  const onVideoSelectEnMobil = (videoFile) => {
    setForm((prevState) => ({ ...prevState, ["video_mobil_en"]: videoFile }));
  };
  return (
    <Layout>
      <ToastContainer />
      <main className="home">
        <Container>
          <div className="h1">Anasayfa</div>
        </Container>

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <br />
                  <div className="container mt-3 pb-5">
                    <h4>Anasayfa Paneli</h4>
                    <hr />

                    <Form onSubmit={onSubmitHandler}>
                      <Label>
                        <b>Video(tr)</b>
                      </Label>

                      <VideoUploader onVideoSelect={onVideoSelect} />
                      <br />
                      <Label>
                        <b>Video(en)</b>
                      </Label>

                      <VideoUploader onVideoSelect={onVideoSelectEn} />
                    
                      <br />
                      <Label>
                        <b>Video Mobil (tr)</b>
                      </Label>

                      <VideoUploader onVideoSelect={onVideoSelectMobil} />
                    
                      <br />
                      <Label>
                        <b>Video Mobil(en)</b>
                      </Label>

                      <VideoUploader onVideoSelect={onVideoSelectEnMobil} />
                    
                      <br />

                      <Label for="title">
                        <b>Slogan (tr)</b>
                      </Label>
                      <CKEditor
  name="title"
  editor={ClassicEditor}
  data={titleContent}
  onReady={(editor) => {
    if (form.title !== "") editor.setData(form.title);
  }}
  onChange={(event, editor) =>
    onChangeHandler("title", editor.getData())
  }
/>
                      {validation.title === "F" && (
                        <div className="val-message">
                          Bu alan boş bırakılamaz.
                        </div>
                      )}

                      <br />

                      <Label for="title_en">
                        <b>Slogan (en)</b>
                      </Label>
                      <CKEditor
  name="title_en"
  editor={ClassicEditor}
  data={titleEnContent}
  onReady={(editor) => {
    if (form.title_en !== "")
      editor.setData(form.title_en);
  }}
  onChange={(event, editor) =>
    onChangeHandler("title_en", editor.getData())
  }
/>
                      {validation.title_en === "F" && (
                        <div className="val-message">
                          Bu alan boş bırakılamaz.
                        </div>
                      )}

                      <br />

                      <Button
                        type="submit"
                        style={{
                          background: "white",
                          color: "black",
                        }}
                        onClick={(e) => onUpdateHandler(e)}
                      >
                        Güncelle
                      </Button>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default FormIndexHomepage;
