import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardTitle,
  CardBody,
  Row,
  Button,
  Table,
  Input,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FormApi } from "../../api/form_api";
import ReactHtmlParser from "react-html-parser";
import Layout from "../../Layout";
const endpoint = "ticaret";

const Ticaret = () => {
  const formApi = new FormApi(endpoint);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [productsAll, setProductsAll] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);

  const goEditProduct = (product) => {
    navigate("/form-ticaret", { state: { product } });
  };

  const deleteProduct = async (e, product) => {
    e.preventDefault();

    alertify.confirm(
      "Silme İşlemi",
      `Silmek istediğinizden emin misiniz?`,
      async function () {
        try {
          const obj = { _id: product._id };
          const result = await formApi.deletePage(obj);
          if (result) {
            alertify.success("İlgili alan silindi..", 2.5);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        } catch (e) {
          alertify.error("ilgili alan silinemedi..", 2.5);
        }
      },
      function () {
        alertify.error("İşlemden vazgeçildi...", 20);
      }
    );
  };

  const loadPage = (products) => {
    return products.map((product) => (
      <tr className="align-middle text-center">
        <td> {product.img ? (
                  <img src={`https://idare.konakglobal.com/uploads/${product.img}`}  height={50} alt="img" />

                ) : (
                  "Resim bulunamadı."
                )}</td>
        <td>{product.title}</td>
        <td className="align-middle">
          {product.desc
            ? ReactHtmlParser(product.desc.substring(0, 250))
            : "İçerik bulunamadı."}
        </td>
        <td>{product.title_en}</td>
        <td className="align-middle">
          {product.desc_en
            ? ReactHtmlParser(product.desc_en.substring(0, 250))
            : "İçerik bulunamadı."}
        </td>
        <td className="align-middle">
          {product.dunya.map((dunyaItem, index) => (
            <div key={index} className="d-flex justify-content-between">
              <b className="text-start">{dunyaItem.title_inner}:</b>
              <ul className="text-end">
                {dunyaItem.countries.map((country, countryIndex) => (
                  <li key={countryIndex}>{country}</li>
                ))}
              </ul>
            </div>
          ))}
        </td>
        <td className="option">
          <BiEdit onClick={(e) => goEditProduct(product)} />
        </td>
      </tr>
    ));
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await formApi.getPage();
      setProducts(res);
      setTempProducts(loadPage(res));
      setProductsAll(res);
    };
    fetchData();
  }, []);

  return (
    <Layout>
      <main className="home">
        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle>
                    <b>Ulaslararası Ticaret</b>
                  </CardTitle>
                  <br />

                  <div className="p-3">
                    <div className="d-lg-flex d-md-flex  justify-content-between p-3"></div>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="table-scroll col-12">
                          <Table striped bordered hover>
                            <thead>
                              <tr className="text-bold text-center">
                              <th>Sayfa Banner</th>
                                <th>Sayfa Başlığı (tr)</th>
                                <th>Sayfa Başlığı (en)</th>
                                <th>Sayfa Sloganı (tr)</th>
                                <th>Sayfa Sloganı (en)</th>
                                <th>Ülkeler</th>
                                <th>Seçenekler</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tempProducts.length === 0 ? (
                                <p className="text-danger p-2">
                                  İlgili alan bulunamadı
                                </p>
                              ) : (
                                tempProducts.slice(0, 10)
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default Ticaret;

