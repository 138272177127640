import React, { useState } from "react";

function PhotoUploader({ index, onPhotoUpload, initialPreview, lang }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(initialPreview);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    onPhotoUpload(index, file, lang);
    setPreview(URL.createObjectURL(file));
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      {selectedFile && <p>Selected: {selectedFile.name}</p>}
      {preview && (
        <div style={{ marginTop: '10px' }}>
          <img
          
            src={preview}
            alt={`Preview ${index + 1}`}
            height={50}
          />
        </div>
      )}
    </div>
  );
}

export default PhotoUploader;
