import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Container, Card, CardTitle, CardBody, Row, Table } from "reactstrap";
import Layout from "../../Layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import alertify from "alertifyjs";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhotoUploader from "../../PhotoUploader/PhotoUploader";
const endpoint = "ticaret";

function FormIndexTicaret() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();
  const [preview, setPreview] = useState("");


  const [form, setForm] = useState({
    title: "",
    desc: "",
    title_en: "",
    desc_en: "",
    img: "",
    dunya: [{ title_inner: "", countries: [] }]
  });

  const [validation, setValidation] = useState({
    title: "",
    desc: "",
    title_en: "",
    desc_en: "",
    img: "",
    dunya: []
  });
  useEffect(() => {
    if (location.state) {
      const { product } = location.state;
      setForm(product);
      setPreview(product.img ? `https://idare.konakglobal.com/uploads/${product.img}` : "");

    }
  }, [location.state]);

  // useEffect(() => {
  //   if (location.state) {
  //     Object.entries(location.state.product).forEach(([key, value]) => {
  //       onChangeHandler(key, value);
  //       setValidation((prevState) => ({ ...prevState }));
  //     });
  //   }
  // }, []);


  useEffect(() => {
    if (location.state) {
      const { product } = location.state;
      setForm({
        title: product.title,
        desc: product.desc,
        title_en: product.title_en,
        desc_en: product.desc_en,
        img: product.img,
        dunya: product.dunya.map((region) => ({
          title_inner: region.title_inner,
          countries: region.countries,
        })),
      });
    }
  }, []);



  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onChangeDunyaHandler = (index, key, value) => {
    const updatedDunya = [...form.dunya];
    if (key === "countries") {
      if (typeof value === "string") {
        updatedDunya[index][key] = value.split(", ");
      } else {
        updatedDunya[index][key] = value;
      }
    } else {
      updatedDunya[index][key] = value;
    }
    setForm((prevState) => ({ ...prevState, dunya: updatedDunya }));
  };



  const addRegion = () => {
    const updatedDunya = [...form.dunya, { title_inner: "", countries: [] }];
    setForm((prevState) => ({ ...prevState, dunya: updatedDunya }));
  };

  const removeRegion = (index) => {
    const updatedDunya = form.dunya.filter((_, i) => i !== index);
    setForm((prevState) => ({ ...prevState, dunya: updatedDunya }));
  };


  const onSubmitHandler = async (e) => {
    e.preventDefault();

    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      toast.error("Lütfen tüm alanları doldurunuz..", 2.5);
      return;
    }
    const productId = location.state.product._id;

    let formData = new FormData();
    formData.append("_id", productId);
    formData.append("title", form.title);
    formData.append("desc", form.desc);
    formData.append("img", form.img);

    formData.append("title_en", form.title_en);
    formData.append("desc_en", form.desc_en);
    formData.append("dunya", JSON.stringify(form.dunya));
    try {
      const rst = await formApi.addPage(formData);
      toast.success(
        "İlgili alan eklendi..",
        (window.location.href = "/ticaret"),
        5000
      );
    } catch (e) {
      toast.error("İlgili alan eklenemedi..", 2.5);
    }
  };

  const onUpdateHandler = async (e) => {
    e.preventDefault();

    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (value === "F") isError = true;
    });

    if (isError) {
      toast.error("Düzenlemeler yapılamadı..", 2.5);
      return;
    }

    const productId = location.state.product._id;

    let formData = new FormData();
    formData.append("_id", productId);
    formData.append("title", form.title);
    formData.append("desc", form.desc);
    formData.append("img", form.img);
    formData.append("title_en", form.title_en);
    formData.append("desc_en", form.desc_en);
    formData.append("dunya", JSON.stringify(form.dunya));

    try {
      const rst = await formApi.updatePage(formData);
      if (rst.error) {
        toast.error(`Hata: ${rst.error}`, 2.5);
      } else {
        toast.success("Düzenlemeler yapıldı..", 2.5);
        window.location.href = "/ticaret";
      }
    } catch (e) {
      toast.error("Düzenlemeler yapılamadı..", 2.5);
    }
  };


  // const handlePhotoUpload = (file) => {
  //   console.log("Selected file:", file);

  //   setForm((prevState) => ({
  //     ...prevState,
  //     img: file
  //   }));

  //   setPreview(URL.createObjectURL(file));
  // };
  //en son bu
  // const handlePhotoUpload = (file) => {
  //   console.log("Selected file:", file);

  //   if (!file) {
  //     console.error("Invalid file selected");
  //     return;
  //   }

  //   setForm((prevState) => ({
  //     ...prevState,
  //     img: file
  //   }));

  //   try {
  //     const previewURL = URL.createObjectURL(file);
  //     console.log("Preview URL:", previewURL);
  //     setPreview(URL.createObjectURL(file));
  //   } catch (error) {
  //     console.error("Failed to create object URL:", error);
  //   }
  // };

  // const handlePhotoUpload = (index, file) => {
  //   console.log("Selected file:", file);

  //   setForm((prevState) => {
  //     const newServices = [...prevState.form];
  //     newServices[index].img = file;
  //     return { ...prevState, form: newServices };
  //   });

  //   const newPreviews = [...preview];
  //   newPreviews[index] = URL.createObjectURL(file);
  //   setPreview(newPreviews);
  // };

  const handlePhotoUpload = (index, file, lang) => {
    console.log("Selected file:", file);

    setForm((prevState) => ({
      ...prevState,
      img: file
    }));

    setPreview(URL.createObjectURL(file));
  };



  return (
    <Layout>
      <ToastContainer />
      <main className="home">
        <Container>
          <div className="h1">Uluslararası Ticaret</div>
        </Container>

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <br />
                  <div className="container mt-3 pb-5">
                    <h4>
                      Uluslararası Ticaret sayfanızdaki ilgili güncellemeleri bu
                      sayfadan yapabilirsiniz.
                    </h4>
                    <hr />

                    <Form onSubmit={onSubmitHandler}>
                      <br />
                      <div className="row col-12">
                        <div className="col-12">

                          <FormGroup>
                            <Label>
                              <b>Uluslar Arası Ticaret Banner</b>
                            </Label>
                            <div
                              style={{
                                marginBottom: "25px",
                                border: "1px solid #ccc",
                                padding: "20px",
                                borderRadius: "5px",
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <Label style={{ marginRight: "10px" }}></Label>
                                <PhotoUploader
                                  index={0}
                                  onPhotoUpload={handlePhotoUpload}
                                  initialPreview={preview || (form.img ? `https://idare.konakglobal.com/uploads/${form.img}` : "")}
                                />
                              </div>
                              <div style={{width:'30%'}}>
                              {preview && <img src={preview} alt="Preview" style={{ marginTop: "10px", maxWidth: "100%" }} />}
                            </div>
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label for="title">
                              <b>Sayfa Başlığı(tr)</b>
                            </Label>
                            <Input
                              id="title"
                              name="title"
                              placeholder="Lütfen Blogyu giriniz..."
                              type="text"
                              onChange={(e) =>
                                onChangeHandler(e.target.name, e.target.value)
                              }
                              value={form.title}
                              valid={validation.title === "T"}
                              invalid={validation.title === "F"}
                            />
                          </FormGroup>
                        </div>
                      </div>

                      <Label for="desc">
                        <b>Sayfa Sloganı(tr)</b>
                      </Label>
                      <CKEditor
                        name="desc"
                        editor={ClassicEditor}
                        data={form.desc}

                        onReady={(editor) => {
                          if (form.desc !== "") editor.setData(form.desc);
                        }}
                        onChange={(event, editor) =>
                          onChangeHandler("desc", editor.getData())
                        }
                      />
                      {validation.desc === "F" && (
                        <div className="val-message">
                          Bu alan boş bırakılamaz.
                        </div>
                      )}

                      <br />
                      <div className="row col-12">
                        <div className="col-12">
                          <FormGroup>
                            <Label for="title_en">
                              <b>Sayfa Başlığı(en) </b>
                            </Label>
                            <Input
                              id="title_en"
                              name="title_en"
                              placeholder="Lütfen Blogyu giriniz..."
                              type="text"
                              onChange={(e) =>
                                onChangeHandler(e.target.name, e.target.value)
                              }
                              value={form.title_en}
                              valid={validation.title_en === "T"}
                              invalid={validation.title_en === "F"}
                            />
                          </FormGroup>
                        </div>
                      </div>

                      <Label for="desc_en">
                        <b>Sayfa Sloganı(en)</b>
                      </Label>
                      <CKEditor
                        name="desc_en"
                        editor={ClassicEditor}
                        data={form.desc_en}

                        onReady={(editor) => {
                          if (form.desc_en !== "") editor.setData(form.desc_en);
                        }}
                        onChange={(event, editor) =>
                          onChangeHandler("desc_en", editor.getData())
                        }
                      />
                      {validation.desc_en === "F" && (
                        <div className="val-message">
                          Bu alan boş bırakılamaz.
                        </div>
                      )}

                      <br />


                      <Label for="dunya">
                        <b>Ülkeler ve bölgeler</b>
                      </Label>
                      {form.dunya.map((item, index) => (

                        <div key={index} className="mb-3">
                          <div className="d-flex align-items-start justify-content-between">

                            <Label for={`regionTitle${index}`}>Bölge Başlığı</Label>
                            <Button
                              color="danger"
                              onClick={() => removeRegion(index)}
                            >
                              &times;
                            </Button>
                          </div>
                          <Input
                            id={`regionTitle${index}`}
                            name={`regionTitle${index}`}
                            type="text"
                            value={item.title_inner}
                            onChange={(e) =>
                              onChangeDunyaHandler(
                                index,
                                "title_inner",
                                e.target.value
                              )
                            }
                          />

                          <Label for={`countries${index}`}>Ülkeler</Label>
                          <Input
                            id={`countries${index}`}
                            name={`countries${index}`}
                            type="text"
                            value={item.countries.join(", ")}
                            onChange={(e) =>
                              onChangeDunyaHandler(
                                index,
                                "countries",
                                e.target.value.split(", ")
                              )
                            }
                          />
                        </div>
                      ))}
                      <Button color="primary" onClick={addRegion}>
                        Bölge Ekle
                      </Button>

                      {"  "}
                      <Button
                        type="submit"
                        style={{
                          background: "white",
                          color: "black",
                        }}
                        onClick={(e) => onUpdateHandler(e)}
                      >
                        Güncelle
                      </Button>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default FormIndexTicaret;
