import { useState, useEffect } from "react";
import { Form, Label, Button } from "reactstrap";
import { Container, Card, CardBody, Row } from "reactstrap";
import Layout from "../../Layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhotoUploader from "../../PhotoUploader/PhotoUploader";

const endpoint = "career";

function FormIndexCareer() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();
  const [preview, setPreview] = useState("");

  const [form, setForm] = useState({
    img: "",
    title_tr: "",
    title_en: "",
    desc_tr: "",
    desc_en: "",
    openjobs_title_tr: "",
    openjobs_title_en: "",
    jobs: []
  });

  const [validation, setValidation] = useState({
    img: "",
    title_tr: "",
    title_en: "",
    desc_tr: "",
    desc_en: "",
    openjobs_title_tr: "",
    openjobs_title_en: "",
    jobs: []
  });

  useEffect(() => {
    if (location.state) {
      const { product } = location.state;
      setForm(product);
      setPreview(product.img ? `https://idare.konakglobal.com/uploads/${product.img}` : "");
    }
  }, [location.state]);

  const validator = (key, value) => {
    if (!value || value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else {
      setValidation((prevState) => ({ ...prevState, [key]: "T" }));
    }
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      toast.error("Formda hatalar var..", { autoClose: 2500 });
      return;
    }

    let formData = new FormData();
    for (let key in form) {
      formData.append(key, form[key]);
    }

    try {
      await formApi.addPage(formData);
      toast.success("İlgili alan eklendi..", { autoClose: 5000 });
      setTimeout(() => {
        window.location.href = "/career";
      }, 1000);
    } catch (e) {
      toast.error("İlgili alan eklenemedi..", { autoClose: 2500 });
    }
  };

  const onUpdateHandler = async (e) => {
    e.preventDefault();

    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (value === "F") isError = true;
    });

    if (isError) {
      toast.error("Düzenlemeler yapılamadı..", { autoClose: 2500 });
      return;
    }

    const productId = location.state.product._id;

    let formData = new FormData();
    formData.append("_id", productId);
    formData.append("title_tr", form.title_tr);
    formData.append("title_en", form.title_en);
    formData.append("desc_tr", form.desc_tr);
    formData.append("desc_en", form.desc_en);
    formData.append("openjobs_title_tr", form.openjobs_title_tr);
    formData.append("openjobs_title_en", form.openjobs_title_en);
    formData.append("img", form.img);

    form.jobs.forEach((job, index) => {
      formData.append(`jobs[${index}][jobs_no]`, job.jobs_no);
      formData.append(`jobs[${index}][jobs_name_tr]`, job.jobs_name_tr);
      formData.append(`jobs[${index}][jobs_desc_tr]`, job.jobs_desc_tr);
      formData.append(`jobs[${index}][jobs_name_en]`, job.jobs_name_en);
      formData.append(`jobs[${index}][jobs_desc_en]`, job.jobs_desc_en);
    });

    try {
      const rst = await formApi.updatePage(formData);
      if (rst.error) {
        toast.error(`Hata: ${rst.error}`, { autoClose: 2500 });
      } else {
        toast.success("Düzenlemeler yapıldı..", { autoClose: 2500 });
        window.location.href = "/career";
      }
    } catch (e) {
      toast.error("Düzenlemeler yapılamadı..", { autoClose: 2500 });
    }
  };

  const handlePhotoUpload = (index, file) => {
    console.log("Selected file:", file);

    setForm((prevState) => ({
      ...prevState,
      img: file
    }));

    setPreview(URL.createObjectURL(file));
  };


  const addJobField = () => {
    setForm((prevState) => ({
      ...prevState,
      jobs: [
        ...prevState.jobs,
        { jobs_no: prevState.jobs.length + 1, jobs_name_tr: "", jobs_desc_tr: "", jobs_name_en: "", jobs_desc_en: "" },
      ],
    }));
  };

  const removeJobField = (index) => {
    setForm((prevState) => {
      const newJobs = prevState.jobs.filter((_, i) => i !== index);
      return { ...prevState, jobs: newJobs };
    });
  };

  return (
    <Layout>
      <ToastContainer />
      <main className="home">
        <Container>
          <div className="h1">Kariyer</div>
        </Container>

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <br />
                  <div className="container mt-3 pb-5">
                    <h4>Kariyer sayfası için ilgili düzenlemeleri bu sayfadan yapabilirsiniz.</h4>
                    <hr />

                    <Form onSubmit={onSubmitHandler}>
                      <br />
                      <Label>
                        <b>Kariyer Banner</b>
                      </Label>
                      <div
                        style={{
                          marginBottom: "25px",
                          border: "1px solid #ccc",
                          padding: "20px",
                          borderRadius: "5px",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Label style={{ marginRight: "10px" }}></Label>
                          
                          <PhotoUploader 
                           
                            index={0}
                            onPhotoUpload={handlePhotoUpload}
                            initialPreview={preview || (form.img ? `https://idare.konakglobal.com/uploads/${form.img}` : "")}
                            
                          />
                        </div>
                        <div style={{width:'30%'}}>
                        {preview && <img src={preview} alt="Preview" style={{ marginTop: "10px", maxWidth: "100%" }} />}
                      </div>
                      </div>
                      <br />

                      <Label for="title_tr">
                        <b>Başlık(tr)</b>
                      </Label>
                      <CKEditor
                        name="title_tr"
                        editor={ClassicEditor}
                        data={form.title_tr}
                        onChange={(event, editor) =>
                          onChangeHandler("title_tr", editor.getData())
                        }
                      />
                      {validation.title_tr === "F" && (
                        <div className="val-message">Bu alan boş bırakılamaz.</div>
                      )}

                      <br />
                      <Label for="title_en">
                        <b>Başlık (en)</b>
                      </Label>
                      <CKEditor
                        name="title_en"
                        editor={ClassicEditor}
                        data={form.title_en}
                        onChange={(event, editor) =>
                          onChangeHandler("title_en", editor.getData())
                        }
                      />
                      {validation.title_en === "F" && (
                        <div className="val-message">Bu alan boş bırakılamaz.</div>
                      )}

                      <br />
                      <Label for="desc_tr">
                        <b>Açıklama (tr)</b>
                      </Label>
                      <CKEditor
                        name="desc_tr"
                        editor={ClassicEditor}
                        data={form.desc_tr}
                        onChange={(event, editor) =>
                          onChangeHandler("desc_tr", editor.getData())
                        }
                      />
                      {validation.desc_tr === "F" && (
                        <div className="val-message">Bu alan boş bırakılamaz.</div>
                      )}

                      <br />
                      <Label for="desc_en">
                        <b>Açıklama (en)</b>
                      </Label>
                      <CKEditor
                        name="desc_en"
                        editor={ClassicEditor}
                        data={form.desc_en}
                        onChange={(event, editor) =>
                          onChangeHandler("desc_en", editor.getData())
                        }
                      />
                      {validation.desc_en === "F" && (
                        <div className="val-message">Bu alan boş bırakılamaz.</div>
                      )}

                      <br />
                      <Label for="openjobs_title_tr">
                        <b>Açık Pozisyonlar (tr)</b>
                      </Label>
                      <CKEditor
                        name="openjobs_title_tr"
                        editor={ClassicEditor}
                        data={form.openjobs_title_tr}
                        onChange={(event, editor) =>
                          onChangeHandler("openjobs_title_tr", editor.getData())
                        }
                      />
                      {validation.openjobs_title_tr === "F" && (
                        <div className="val-message">Bu alan boş bırakılamaz.</div>
                      )}

                      <br />
                      <Label for="openjobs_title_en">
                        <b>Açık Pozisyonlar (en)</b>
                      </Label>
                      <CKEditor
                        name="openjobs_title_en"
                        editor={ClassicEditor}
                        data={form.openjobs_title_en}
                        onChange={(event, editor) =>
                          onChangeHandler("openjobs_title_en", editor.getData())
                        }
                      />
                      {validation.openjobs_title_en === "F" && (
                        <div className="val-message">Bu alan boş bırakılamaz.</div>
                      )}

                      <br />
                      
                      {form.jobs.map((job, index) => (
                        <div key={index}>
                          <div className="d-flex align-items-start justify-content-between">

                          <Label for={`jobs_name_tr_${index}`}>
                            <b>{`İş İsmi (tr)  ${index + 1}`}</b>
                            
                          </Label>
                          <Button
                              color="danger"
                              onClick={() => removeJobField(index)}
                            >
                              &times;
                            </Button>
                            </div>
                          <CKEditor
                            name={`jobs_name_tr_${index}`}
                            editor={ClassicEditor}
                            onReady={(editor) => {
                              editor.setData(job.jobs_name_tr || "");
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setForm((prevState) => {
                                const newCareer = [...prevState.jobs];
                                newCareer[index].jobs_name_tr = data;
                                return {
                                  ...prevState,
                                  jobs: newCareer,
                                };
                              });
                            }}
                          />
                          {validation.jobs[index]?.jobs_name_tr === "F" && (
                            <div className="val-message">Bu alan boş bırakılamaz.</div>
                          )}

                          <br />
                          <Label for={`jobs_name_en_${index}`}>
                            <b>{`İş İsmi (en) ${index + 1}`}</b>
                          </Label>
                          <CKEditor
                            name={`jobs_name_en_${index}`}
                            editor={ClassicEditor}
                            onReady={(editor) => {
                              editor.setData(job.jobs_name_en || "");
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setForm((prevState) => {
                                const newCareer = [...prevState.jobs];
                                newCareer[index].jobs_name_en = data;
                                return {
                                  ...prevState,
                                  jobs: newCareer,
                                };
                              });
                            }}
                          />
                          {validation.jobs[index]?.jobs_name_en === "F" && (
                            <div className="val-message">Bu alan boş bırakılamaz.</div>
                          )}

                          <br />
                          <Label for={`jobs_desc_tr_${index}`}>
                            <b>{`İş Açıklaması (tr) ${index + 1}`}</b>
                          </Label>
                          <CKEditor
                            name={`jobs_desc_tr_${index}`}
                            editor={ClassicEditor}
                            onReady={(editor) => {
                              editor.setData(job.jobs_desc_tr || "");
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setForm((prevState) => {
                                const newCareer = [...prevState.jobs];
                                newCareer[index].jobs_desc_tr = data;
                                return {
                                  ...prevState,
                                  jobs: newCareer,
                                };
                              });
                            }}
                            
                          />
                          {validation.jobs[index]?.jobs_desc_tr === "F" && (
                            <div className="val-message">Bu alan boş bırakılamaz.</div>
                          )}

                          <br />
                          <Label for={`jobs_desc_en_${index}`}>
                            <b>{`İş Açıklaması (en) ${index + 1}`}</b>
                          </Label>
                          <CKEditor
                            name={`jobs_desc_en_${index}`}
                            editor={ClassicEditor}
                            onReady={(editor) => {
                              editor.setData(job.jobs_desc_en || "");
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setForm((prevState) => {
                                const newCareer = [...prevState.jobs];
                                newCareer[index].jobs_desc_en = data;
                                return {
                                  ...prevState,
                                  jobs: newCareer,
                                };
                              });
                            }}
                            
                          />
                          {validation.jobs[index]?.jobs_desc_en === "F" && (
                            <div className="val-message">Bu alan boş bırakılamaz.</div>
                          )}
                          <hr />
                          <br/>
                        </div>
                        
                      ))}
                      <br/>
                      <Button color="dark" type="button" onClick={addJobField}  >
                       İş İlanı  Ekle
                      </Button>
                      {"  "}
                      <Button
                        type="submit"
                        style={{
                          background: "white",
                          color: "black",
                        }}
                        onClick={(e) => onUpdateHandler(e)}
                      >
                        Güncelle
                      </Button>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default FormIndexCareer;
