import React, { useState, useEffect } from "react";
import { Container, Card, CardTitle, CardBody, Row, Button, Table } from "reactstrap";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FormApi } from "../../api/form_api";
import ReactHtmlParser from "react-html-parser";
import Layout from "../../Layout";
import Paginator from "../../Paginator";

const endpoint = "services";

const Services = () => {
  const formApi = new FormApi(endpoint);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);

  const goEditProduct = (product) => {
    navigate("/form-services", { state: { product } });
  };

  const deleteProduct = async (e, product) => {
    e.preventDefault();

    alertify.confirm(
      "Silme İşlemi",
      `Silmek istediğinizden emin misiniz?`,
      async function () {
        try {
          const obj = { _id: product._id };
          const result = await formApi.deletePage(obj);
          if (result) {
            alertify.success("İlgili alan silindi..", 2.5);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        } catch (e) {
          alertify.error("İlgili alan silinemedi..", 2.5);
        }
      },
      function () {
        alertify.error("İşlemden vazgeçildi...", 20);
      }
    );
  };



  const loadPage = (products) => {
    return products.map((product) => (
      <tr className="align-middle text-center" key={product.services_id}>
        <td className="align-middle">
        <img src={`https://idare.konakglobal.com/uploads/${product.services_img}`} alt="Product" height={50} />
        </td>
        <td className="align-middle">
          {product.services_maintitle
            ? ReactHtmlParser(product.services_maintitle.substring(0, 250))
            : "İçerik bulunamadı."}
        </td>
        <td className="align-middle">
          {product.services_maintitle_en
            ? ReactHtmlParser(product.services_maintitle_en.substring(0, 250))
            : "Content not found."}
        </td>
        <td className="align-middle">
          {product.services_desc
            ? ReactHtmlParser(product.services_desc.substring(0, 250))
            : "İçerik bulunamadı."}
        </td>
        <td className="align-middle">
          {product.services_desc_en
            ? ReactHtmlParser(product.services_desc_en.substring(0, 250))
            : "Content not found."}
        </td>
        <td className="align-middle">
          {product.services && product.services.length > 0 ? (
            product.services.map((service, index) => (
              <div key={index}>
                {service.services_title
                  ? ReactHtmlParser(service.services_title.substring(0, 250))
                  : "İçerik bulunamadı."}
                <br />
                
                {service.img ? (
                  <img src={`https://idare.konakglobal.com/uploads/${service.img}`}  height={50} />

                ) : (
                  "Resim bulunamadı."
                )}
                
                <br />
              </div>
            ))
          ) : (
            "İçerik bulunamadı."
          )}
        </td>

        <td className="align-middle">
          {product.services && product.services.length > 0 ? (
            product.services.map((service, index) => (
              <div key={index}>
                {service.services_title_en
                  ? ReactHtmlParser(service.services_title_en.substring(0, 250))
                  : "Content not found."}
                <br />
                
                {service.img_en ? (
                  <img src={`https://idare.konakglobal.com/uploads/${service.img_en}`}  height={50} />

                ) : (
                  "Image not found."
                )}
                
                <br />
              </div>
            ))
          ) : (
            "Content not found."
          )}
        </td>
        
        <td className="option">
          <BiEdit onClick={(e) => goEditProduct(product)} />
        </td>
      </tr>
    ));
  };
  
  

  useEffect(() => {
    const fetchData = async () => {
      const res = await formApi.getPage();
      setProducts(res);
      setTempProducts(loadPage(res));
    };
    fetchData();
  }, []);

  return (
    <Layout>
      <main className="home">
        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle><b>Hizmetlerimiz</b></CardTitle>
                  <br />
                  <div className="p-3">
                    <div className="d-lg-flex d-md-flex  justify-content-between p-3">
                      {/* <Button className="bg-black" onClick={() => navigate("/form-services")}>Hizmet Ekle</Button> */}
                    </div>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="table-scroll col-12">
                          <Table striped bordered hover>
                            <thead>
                              <tr className="text-bold text-center">
                                {["Hizmetler Banner","Başlık", "İngilizce Başlık", "İçerik", "İngilizce İçerik", "Fotoğraf ve Açıklaması" ,"İngilizce Fotoğraf ve Açıklaması "].map((header) => (
                                  <th>{header}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {tempProducts.length === 0 ? (
                                <p className="text-danger p-2">Hizmetler içeriği bulunamadı.</p>
                              ) : (
                                tempProducts.slice(0, 10)
                              )}
                            </tbody>
                          </Table>
                          {products ? (
                            <Paginator len={products.length} data={products} setTempProducts={setTempProducts} loadPage={loadPage} />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default Services;