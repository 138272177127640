import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

function VideoUploader(props) {
  const [fileName, setFileName] = useState("");
  const [fileSizeError, setFileSizeError] = useState(false);

  useEffect(() => {
    if (props.existingVideo) {
      setFileName(props.existingVideo.name);
    }
  }, [props.existingVideo]);

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    if (file.size > 10000000) { // 10 MB limit
      setFileSizeError(true);
      return;
    }
    setFileSizeError(false);
    setFileName(file.name); 
    props.onVideoSelect(file);
  }, [props]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'video/*', maxFiles: 1 });

  return (
    <div {...getRootProps()} style={dropzoneStyles}>
      <input {...getInputProps()} />
      {fileName ? (
        <p>Seçilen video: {fileName}</p>
      ) : isDragActive ? (
        <p>Dosyayı bırakın...</p>
      ) : (
        <p>Video dosyasını buraya sürükleyin veya tıklayın</p>
      )}
      {fileSizeError && <p style={{ color: 'red' }}>Uyarı: Video boyutu 10 MB'dan büyük olamaz!</p>}
    </div>
  );
}

const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

export default VideoUploader;
