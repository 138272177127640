import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardTitle,
  CardBody,
  Row,
  Button,
  Table,
  Input,
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FormApi } from "../../api/form_api";
import ReactHtmlParser from "react-html-parser";
import Layout from "../../Layout";
import Paginator from "../../Paginator";
const endpoint = "homepage";

const Homepage = () => {
  const formApi = new FormApi(endpoint);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [productsAll, setProductsAll] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);


  const goEditProduct = (product) => {
    navigate("/form-home-main", { state: { product } });
  };

 

  const loadPage = (products) => {
    return products.map((product) => (
      <tr className="align-middle text-center">
        <td className="align-middle">
          {product.title
            ? ReactHtmlParser(product.title.substring(0, 250))
            : "İçerik bulunamadı."}
        </td>{" "}
        <td className="align-middle">
          {product.title_en
            ? ReactHtmlParser(product.title_en.substring(0, 250))
            : "İçerik bulunamadı."}
        </td>
        <td className="">
          {" "}
          <video
            src={`https://idare.konakglobal.com/uploads/${product.video}`}
            height={50}
          />
        </td>
        <td className="">
          {" "}
          <video
            src={`https://idare.konakglobal.com/uploads/${product.video_en}`}
            height={50}
          />
        </td>
        <td className="">
          {" "}
          <video
            src={`https://idare.konakglobal.com/uploads/${product.video_mobil}`}
            height={50}
          />
        </td>
        <td className="">
          {" "}
          <video
            src={`https://idare.konakglobal.com/uploads/${product.video_mobil_en}`}
            height={50}
          />
        </td>
        <td className="option">
          <BiEdit onClick={(e) => goEditProduct(product)} />
        </td>
      </tr>
    ));
  };


  useEffect(() => {
    const fetchData = async () => {
      const res = await formApi.getPage();
      setProducts(res);
      setTempProducts(loadPage(res));
      setProductsAll(res);
    };
    fetchData();
  }, []);

  return (
    <Layout>
      <main className="home">
        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle>
                    <b>Anasayfa</b>
                  </CardTitle>
                  <br />

                  <div className="p-3">
                    <div className="d-lg-flex d-md-flex  justify-content-between p-3">
                     
                    </div>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="table-scroll col-12">
                          <Table striped bordered hover>
                            <thead>
                              <tr className="text-bold text-center">
                                <th>Slogan (tr)</th>
                                <th>Slogan (en)</th>
                                <th>Video (tr)</th>
                                <th>Video (en)</th>
                                <th>Video Mobil(tr)</th>
                                <th>Video Mobil (en)</th>
                                <th>Seçenekler</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tempProducts.length === 0 ? (
                                <p className="text-danger p-2">
                                  Anasayfa bilgisi bulunamadı
                                </p>
                              ) : (
                                tempProducts.slice(0, 10)
                              )}
                            </tbody>
                          </Table>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default Homepage;
