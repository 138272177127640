import { useState, useEffect, useCallback } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Container, Card, CardTitle, CardBody, Row, Table } from "reactstrap";
import Layout from "../../Layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhotoUploader from "../../PhotoUploader/PhotoUploader";

const endpoint = "services";

function FormIndexServices() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();
  const [previewBanner, setPreviewBanner] = useState("");

  const [form, setForm] = useState({
    services_id: "",
    services_maintitle: "",
    services_maintitle_en: "",
    services_desc: "",
    services_desc_en: "",
    services_img:"",
    services: [],
  });

  const [previews, setPreviews] = useState(
    Array.from({ length: form.services.length }, () => "")
  );

  // const handlePhotoUpload = (index, file) => {
  //   console.log("Selected file:", file);

  //   setForm((prevState) => {
  //     const newServices = [...prevState.services];
  //     newServices[index].img = file;
  //     return { ...prevState, services: newServices };
  //   });

  //   const newPreviews = [...previews];
  //   newPreviews[index] = URL.createObjectURL(file);
  //   setPreviews(newPreviews);
  // };

  const handlePhotoUpload = (index, file, lang) => {
    console.log("Selected file:", file);
  
    if (file && file instanceof File) {
      setForm((prevState) => {
        const newServices = [...prevState.services];
        const serviceToUpdate = newServices[index];
        if (lang === "en") {
          serviceToUpdate.img_en = file;
        } else {
          serviceToUpdate.img = file;
        }
        newServices[index] = serviceToUpdate;
        return { ...prevState, services: newServices };
      });
  
      const newPreviews = [...previews];
      newPreviews[index] = URL.createObjectURL(file);
      setPreviews(newPreviews);
    } else {
      console.error("Invalid file selected");
    }
  };

  useEffect(() => {
    if (location.state) {
      Object.entries(location.state.product).forEach(([key, value]) => {
        onChangeHandler(key, value);
        const { product } = location.state;
        setPreviewBanner(product.services_img ? `https://idare.konakglobal.com/uploads/${product.services_img}` : "");
      });
    }
  }, [location.state]);

 

  const onChangeHandler = (key, value) => {
    if (key === "_id") {
      setForm((prevState) => ({ ...prevState, [key]: value }));
    } else {
      setForm((prevState) => ({
        ...prevState,
        [key]: value,
        _id: location.state?.product?._id || prevState._id,
      }));
    }
  };

  const onUpdateHandler = async (e) => {
    e.preventDefault();
    console.log("Form data before submission:", form);

    const formData = new FormData();
    formData.append("_id", form._id);
    formData.append("services_maintitle", form.services_maintitle);
    formData.append("services_maintitle_en", form.services_maintitle_en);
    formData.append("services_desc", form.services_desc);
    formData.append("services_desc_en", form.services_desc_en);
    console.log("servicess",form.services_img)
    if (form.services_img instanceof File) {
      formData.append("services_img", form.services_img);
    }
    

    form.services.forEach((service, index) => {
      formData.append(`services[${index}][services_no]`, service.services_no);
      formData.append(
        `services[${index}][services_title]`,
        service.services_title
      );
      formData.append(
        `services[${index}][services_title_en]`,
        service.services_title_en
      );

      if (service.img) {
        formData.append(`services[${index}][img]`, service.img.name);
        if (service.img instanceof File) {
          formData.append(`img`, service.img);
        }
      }

      if (service.img_en) {
        formData.append(`services[${index}][img_en]`, service.img_en.name);
        if (service.img_en instanceof File) {
          formData.append(`img_en`, service.img_en);
        }
      }
    });

    try {
      const rst = await formApi.updatePage(formData);
      toast.success("Düzenlemeler yapıldı..", { autoClose: 2500 });
      window.location.href = "/services"; 
    } catch (e) {
      toast.error("Düzenlemeler yapılamadı..", { autoClose: 2500 });
    }
  };

  const handlePhotoUploadBanner = (index,file) => {
    console.log("Selected file:", file);

    setForm((prevState) => ({
      ...prevState,
      services_img: file
    }));
    console.log("aaa",form.services_img)
    setPreviewBanner(URL.createObjectURL(file));
  };


  return (
    <Layout>
      <ToastContainer />
      <main className="home">
        <Container>
          <div className="h1">Hizmetlerimiz</div>
        </Container>

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <br />
                  <div className="container mt-3 pb-5">
                    <h4>
                      Hizmetlerimiz sayfası için ilgili düzenlemeleri bu
                      sayfadan yapabilirsiniz.
                    </h4>
                    <hr />

                    <Form>
                      <br />

                      <FormGroup>
                        <Label for="services_maintitle">
                          <b>Hizmetler Banner</b>
                        </Label>
                        <div
                              style={{
                                marginBottom: "25px",
                                border: "1px solid #ccc",
                                padding: "20px",
                                borderRadius: "5px",
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <Label style={{ marginRight: "10px" }}></Label>
                                <PhotoUploader
                                  index={0}
                                  onPhotoUpload={handlePhotoUploadBanner}
                                  initialPreview={previewBanner || (form.services_img ? `https://idare.konakglobal.com/uploads/${form.services_img}` : "")}
                                />


                              </div>
                              <div style={{width:'30%'}}>
                              {previewBanner && <img src={previewBanner} alt="Preview" style={{ marginTop: "10px", maxWidth: "100%" }} />}
                            </div>
                            </div>
                      </FormGroup>

                      <FormGroup>
                        <Label for="services_maintitle">
                          <b>Hizmetler Başlığı</b>
                        </Label>
                        <Input
                          type="text"
                          name="services_maintitle"
                          id="services_maintitle"
                          placeholder="Hizmetler başlığı girin"
                          value={form.services_maintitle}
                          onChange={(e) =>
                            onChangeHandler(
                              "services_maintitle",
                              e.target.value
                            )
                          }
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="services_maintitle_en">
                          <b>Hizmetler Başlığı (en)</b>
                        </Label>
                        <Input
                          type="text"
                          name="services_maintitle_en"
                          id="services_maintitle_en"
                          placeholder="Hizmetler başlığı girin"
                          value={form.services_maintitle_en}
                          onChange={(e) =>
                            onChangeHandler(
                              "services_maintitle_en",
                              e.target.value
                            )
                          }
                        />
                      </FormGroup>

                      <br />
                      <FormGroup>
                        <Label for="services_desc">
                          <b>Hizmetler Açıklaması</b>
                        </Label>
                        <CKEditor
                          name="services_desc"
                          editor={ClassicEditor}
                          onReady={(editor) => {
                            if (form.services_desc !== "")
                              editor.setData(form.services_desc);
                          }}
                          onChange={(event, editor) =>
                            onChangeHandler("services_desc", editor.getData())
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="services_desc_en">
                          <b>Hizmetler Açıklaması EN</b>
                        </Label>
                        <CKEditor
                          name="services_desc_en"
                          editor={ClassicEditor}
                          onReady={(editor) => {
                            if (form.services_desc_en !== "")
                              editor.setData(form.services_desc_en);
                          }}
                          onChange={(event, editor) =>
                            onChangeHandler(
                              "services_desc_en",
                              editor.getData()
                            )
                          }
                        />
                      </FormGroup>

                      <br />

                      <Label>
                        <b>Hizmetler Fotoğraf Açıklamaları</b>
                      </Label>
                      {form.services.map((service, index) => (
                        <div key={index}>
                          <Label>{`Fotoğraf Açıklaması ${index + 1}`}</Label>

                          <CKEditor
                            name={`services_title_${index}`}
                            editor={ClassicEditor}
                            onReady={(editor) => {
                              editor.setData(service.services_title || "");
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setForm((prevState) => {
                                const newServices = [...prevState.services];
                                newServices[index].services_title = data;
                                return {
                                  ...prevState,
                                  services: newServices,
                                };
                              });
                            }}
                          />
                          <br />
                          <br />
                        </div>
                      ))}

                      <Label>
                        <b>Hizmetler Fotoğraf Açıklamaları İngilizce</b>
                      </Label>
                      {form.services.map((service, index) => (
                        <div key={index}>
                          <Label>{`Fotoğraf Açıklaması ${index + 1}`}</Label>

                          <CKEditor
                            name={`services_title_en_${index}`}
                            editor={ClassicEditor}
                            onReady={(editor) => {
                              editor.setData(service.services_title_en || "");
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setForm((prevState) => {
                                const newServices = [...prevState.services];
                                newServices[index].services_title_en = data;
                                return {
                                  ...prevState,
                                  services: newServices,
                                };
                              });
                            }}
                          />
                          <br />
                          <br />
                        </div>
                      ))}

                      <FormGroup>
                        <Label>
                          <b>Fotoğraflar</b>
                        </Label>
                        {form.services.map((service, index) => (
                          <div
                            key={index}
                            style={{
                              marginBottom: "25px",
                              border: "1px solid #ccc",
                              padding: "20px",
                              borderRadius: "5px",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Label
                                style={{ marginRight: "10px" }}
                              >{`Fotoğraf ${index + 1}`}</Label>
      

                              <PhotoUploader
                                index={index}
                                onPhotoUpload={handlePhotoUpload}
                                initialPreview={
                                  previews[index] ||
                                  (service.img
                                    ? `https://idare.konakglobal.com/uploads/${service.img}`
                                    : "")
                                }
                                lang="tr"
                              />
                            </div>
                          </div>
                        ))}
                      </FormGroup>

                      <FormGroup>
                        <Label>
                          <b>Fotoğraflar İngilizce</b>
                        </Label>
                        {form.services.map((service, index) => (
                          <div
                            key={index}
                            style={{
                              marginBottom: "25px",
                              border: "1px solid #ccc",
                              padding: "20px",
                              borderRadius: "5px",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Label
                                style={{ marginRight: "10px" }}
                              >{`Fotoğraf ${index + 1}`}</Label>
     

                              <PhotoUploader
                                index={index}
                                onPhotoUpload={handlePhotoUpload}
                                initialPreview={
                                  previews[index] ||
                                  (service.img_en
                                    ? `https://idare.konakglobal.com/uploads/${service.img_en}`
                                    : "")
                                }
                                lang="en"
                              />
                            </div>
                          </div>
                        ))}
                      </FormGroup>

                      <br />
                      {"  "}
                      <Button
                        type="submit"
                        style={{
                          background: "white",
                          color: "black",
                        }}
                        onClick={(e) => onUpdateHandler(e)}
                      >
                        Güncelle
                      </Button>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default FormIndexServices;