import { lazy, Suspense } from "react";
import { Routes as Switcher, Route, Navigate } from "react-router-dom";
import Loader from "../components/Loader";
import Login from "../pages/Login/Login";

import HomeMain from "../pages/Home/HomeMain";
import AboutMain from "../pages/About/AboutMain";
import FormIndexAbout from "../pages/About/FormIndexAbout";
import FormIndexContact from "../pages/Contact/FormIndexContact";
import ContactMain from "../pages/Contact/ContactMain";
import FormIndexTicaret from "../pages/Ticaret/FormIndexTicaret";
import TicaretMain from "../pages/Ticaret/TicaretMain";
import FormIndexServices from "../pages/Services/FormIndexServices";
import ServicesMain from "../pages/Services/ServicesMain"
// const NotFound = lazy(() => import("../pages/NotFound"));
import HomepageMain from "../pages/Homepage/HomepageMain";
import FormIndexHomepage from "../pages/Homepage/FormIndexHomepage";
import FormIndexCareer from "../pages/Career/FormIndexCareer";
import CareerMain from "../pages/Career/CareerMain";
function Routes() {
  const routeConfig = [
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/home",
      component: HomeMain,
    },
    {
      path:"home-main",
      component: HomepageMain
    },
    {
      path:"form-home-main",
      component: FormIndexHomepage
    },
    {
      path:"form-career",
      component: FormIndexCareer
    },

    {
      path:"career",
      component: CareerMain
    },
   
  
    {
      path:"about",
      component: AboutMain
    },
    {
      path:"form-about",
      component: FormIndexAbout
    },
    {
      path:"form-contact",
      component: FormIndexContact
    },
    {
      path:"contact",
      component: ContactMain
    },
    {
      path:"form-ticaret",
      component: FormIndexTicaret
    },
    {
      path:"ticaret",
      component: TicaretMain
    },
    {
      path:"form-services",
      component: FormIndexServices
    },
    {
      path:"services",
      component: ServicesMain
    },
  
  ];
  return (
  <Switcher>
      {routeConfig.map((route) => (
        <Route
          key={route.path}
          exact
          path={route.path}
          element={
            <Suspense fallback={<Loader />}>
              <route.component />
            </Suspense>
          }
        />
      ))}
    
    </Switcher>

  );
}

export default Routes;
