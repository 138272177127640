import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import Routes from "./routes";
import UseAuth from "./hooks/UseAuth";
import "./App.css"
function App() {
  console.log("version 1.0");

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
       <UseAuth><Routes /></UseAuth> 
      </PersistGate>
    </Provider>
  );
}

export default App;
