import React, { useState, useEffect } from "react";
import { Container, Card, CardTitle, CardBody, Row, Button, Table, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";

import Layout from "../../Layout";

import Footer from "../../Layout/Footer";
import Contact from "./Contact";




const ContactMain = () => {


  return (
    <Layout>
      <main className="home">
        <Container>
          <div className="h1">İletişim</div>
        </Container>

<Contact/>
       <Footer/>
      </main>
    </Layout>
  );
};

export default ContactMain;
