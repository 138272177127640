import React, { useState, useEffect } from "react";
import { Container, Card, CardTitle, CardBody, Row, Button, Table, Input } from "reactstrap";


import Layout from "../../Layout";

import Footer from "../../Layout/Footer";
import Ticaret from "./Ticaret";





const TicaretMain = () => {


  return (
    <Layout>
      <main className="home">
        <Container>
          <div className="h1">Ticaret Yazıları</div>
        </Container>

<Ticaret/>
       <Footer/>
      </main>
    </Layout>
  );
};

export default TicaretMain;
