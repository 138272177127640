import Header from "./Header";
import Footer from "./Footer";
import Aside from "./Aside";
import { Fragment } from "react";

function Layout(props) {
  const { children } = props;

  return (
    <Fragment>
      <Header />
      <Aside />
      <>{children}</>
      {/* <Footer /> */}
    </Fragment>
  );
}

export default Layout;
