import { useState, useEffect } from "react";
import { Form, Label, Button } from "reactstrap";
import { Container, Card, CardBody, Row } from "reactstrap";
import Layout from "../../Layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhotoUploader from "../../PhotoUploader/PhotoUploader";
const endpoint = "about";

function FormIndexAbout() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();
  const [preview, setPreview] = useState("");

  const [form, setForm] = useState({
    biz_kimiz_tr: "",
    misyon_tr: "",
    vizyon_tr: "",
    biz_kimiz_en: "",
    misyon_en: "",
    vizyon_en: "",
    img : "",
  });

  const [validation, setValidation] = useState({
    biz_kimiz_tr: "",
    misyon_tr: "",
    vizyon_tr: "",
    biz_kimiz_en: "",
    misyon_en: "",
    vizyon_en: "",
    img : "",
  });

  useEffect(() => {
    if (location.state) {
      const { product } = location.state;
      setForm(product);
      setPreview(product.img ? `https://idare.konakglobal.com/uploads/${product.img}` : "");

    }
  }, [location.state]);

  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else {
      setValidation((prevState) => ({ ...prevState, [key]: "T" }));
    }
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      toast.error("Formda hatalar var..", 2.5);
      return;
    }

    let formData = new FormData();
    for (let key in form) {
      formData.append(key, form[key]);
    }

    try {
      await formApi.addPage(formData);
      toast.success("İlgili alan eklendi..", { autoClose: 5000 });
      setTimeout(() => {
        window.location.href = "/about";
      }, 1000);
    } catch (e) {
      toast.error("İlgili alan eklenemedi..", 2.5);
    }
  };

  // const onUpdateHandler = async (e) => {
  //   e.preventDefault();

  //   let isError = false;
  //   Object.entries(validation).forEach(([key, value]) => {
  //     if (validator(key, value)) isError = true;
  //   });

  //   if (isError) {
  //     toast.error("Düzenlemeler yapılamadı..", 2.5);
  //     return;
  //   }

  //   let formData = new FormData();
  //   for (let key in form) {
  //     formData.append(key, form[key]);
  //   }

  //   try {
  //     await formApi.updatePage(formData);
  //     toast.success("Düzenlemeler yapıldı..", { autoClose: 1000 });
  //     setTimeout(() => {
  //       window.location.href = "/about";
  //     }, 1000);
  //   } catch (e) {
  //     toast.error("Düzenlemeler yapılamadı..", 1000);
  //   }
  // };

  const onUpdateHandler = async (e) => {
    e.preventDefault();

    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (value === "F") isError = true;
    });

    if (isError) {
      toast.error("Düzenlemeler yapılamadı..", 2.5);
      return;
    }

    const productId = location.state.product._id;

    let formData = new FormData();
    formData.append("_id", productId);
    formData.append("biz_kimiz_tr", form.biz_kimiz_tr);
    formData.append("misyon_tr", form.misyon_tr);
    formData.append("vizyon_tr", form.vizyon_tr);
    formData.append("title_en", form.title_en);
    formData.append("biz_kimiz_en", form.biz_kimiz_en);
    formData.append("misyon_en", form.misyon_en);
    formData.append("vizyon_en", form.vizyon_en);
    formData.append("img", form.img);


    try {
      const rst = await formApi.updatePage(formData);
      if (rst.error) {
        toast.error(`Hata: ${rst.error}`, 2.5);
      } else {
        toast.success("Düzenlemeler yapıldı..", 2.5);
        window.location.href = "/about";
      }
    } catch (e) {
      toast.error("Düzenlemeler yapılamadı..", 2.5);
    }
  };

  const handlePhotoUpload = (index, file, lang) => {
    console.log("Selected file:", file);

    setForm((prevState) => ({
      ...prevState,
      img: file
    }));

    setPreview(URL.createObjectURL(file));
  };

  return (
    <Layout>
      <ToastContainer />
      <main className="home">
        <Container>
          <div className="h1">Hakkımızda</div>
        </Container>

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <br />
                  <div className="container mt-3 pb-5">
                    <h4>Hakkımızda sayfası için ilgili düzenlemeleri bu sayfadan yapabilirsiniz.</h4>
                    <hr />

                    <Form onSubmit={onSubmitHandler}>
                      <br />

                      <Label>
                              <b>Hakkımızda Banner</b>
                            </Label>
                            <div
                              style={{
                                marginBottom: "25px",
                                border: "1px solid #ccc",
                                padding: "20px",
                                borderRadius: "5px",
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <Label style={{ marginRight: "10px" }}></Label>
                                <PhotoUploader
                                  index={0}
                                  onPhotoUpload={handlePhotoUpload}
                                  initialPreview={preview || (form.img ? `https://idare.konakglobal.com/uploads/${form.img}` : "")}
                                />
                              </div>
                              <div style={{width:'30%'}}>
                              {preview && <img src={preview} alt="Preview" style={{ marginTop: "10px", maxWidth: "100%" }} />}
                            </div>
                            </div>

                      <Label for="biz_kimiz_tr">
                        <b>Biz Kimiz (tr)</b>
                      </Label>
                      <CKEditor
                        name="biz_kimiz_tr"
                        editor={ClassicEditor}
                        data={form.biz_kimiz_tr}
                        onChange={(event, editor) =>
                          onChangeHandler("biz_kimiz_tr", editor.getData())
                        }
                      />
                      {validation.biz_kimiz_tr === "F" && (
                        <div className="val-message">Bu alan boş bırakılamaz.</div>
                      )}

                      <br />
                      <Label for="biz_kimiz_en">
                        <b>Biz Kimiz (en)</b>
                      </Label>
                      <CKEditor
                        name="biz_kimiz_en"
                        editor={ClassicEditor}
                        data={form.biz_kimiz_en}
                        onChange={(event, editor) =>
                          onChangeHandler("biz_kimiz_en", editor.getData())
                        }
                      />
                      {validation.biz_kimiz_en === "F" && (
                        <div className="val-message">Bu alan boş bırakılamaz.</div>
                      )}

                      <br />
                      <Label for="misyon_tr">
                        <b>Misyon (tr)</b>
                      </Label>
                      <CKEditor
                        name="misyon_tr"
                        editor={ClassicEditor}
                        data={form.misyon_tr}
                        onChange={(event, editor) =>
                          onChangeHandler("misyon_tr", editor.getData())
                        }
                      />
                      {validation.misyon_tr === "F" && (
                        <div className="val-message">Bu alan boş bırakılamaz.</div>
                      )}

                      <br />
                      <Label for="misyon_en">
                        <b>Misyon (en)</b>
                      </Label>
                      <CKEditor
                        name="misyon_en"
                        editor={ClassicEditor}
                        data={form.misyon_en}
                        onChange={(event, editor) =>
                          onChangeHandler("misyon_en", editor.getData())
                        }
                      />
                      {validation.misyon_en === "F" && (
                        <div className="val-message">Bu alan boş bırakılamaz.</div>
                      )}

                      <br />
                      <Label for="vizyon_tr">
                        <b>Vizyon (tr)</b>
                      </Label>
                      <CKEditor
                        name="vizyon_tr"
                        editor={ClassicEditor}
                        data={form.vizyon_tr}
                        onChange={(event, editor) =>
                          onChangeHandler("vizyon_tr", editor.getData())
                        }
                      />
                      {validation.vizyon_tr === "F" && (
                        <div className="val-message">Bu alan boş bırakılamaz.</div>
                      )}

                      <br />
                      <Label for="vizyon_en">
                        <b>Vizyon (en)</b>
                      </Label>
                      <CKEditor
                        name="vizyon_en"
                        editor={ClassicEditor}
                        data={form.vizyon_en}
                        onChange={(event, editor) =>
                          onChangeHandler("vizyon_en", editor.getData())
                        }
                      />
                      {validation.vizyon_en === "F" && (
                        <div className="val-message">Bu alan boş bırakılamaz.</div>
                      )}

                      <br />
                      <Button color="dark" type="submit" onClick={(e) => onSubmitHandler(e)}>
                        Ekle
                      </Button>
                      {"  "}
                      <Button
                        type="submit"
                        style={{
                          background: "white",
                          color: "black",
                        }}
                        onClick={(e) => onUpdateHandler(e)}
                      >
                        Güncelle
                      </Button>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default FormIndexAbout;
