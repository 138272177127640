import React from "react";
import { useState } from "react";
import { Button, Input } from "reactstrap";
import Indicator from "./Indicator";
import alertify from "alertifyjs";
import { MDBContainer, MDBRow, MDBCol, MDBInput } from "mdb-react-ui-kit";
import logo from "../../assets/osi_logo_white.webp"
import { FormApi } from "../../api/form_api";

const endPoint = "user/login";

function Login() {
  const formApi = new FormApi(endPoint);
  const [user, setUser] = useState({
    name: "",
    password: "",
  });
  const [loggining, setLoggining] = useState(false);
  const [validation, setValidation] = useState({
    name: "",
    password: "",
  });

  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  const onChangeHandler = (key, value) => {
    setUser((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    setLoggining(true);
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (!isError) {
      try {
        const res = await formApi.addPage(user);

        localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE, res.data);
        setTimeout(() => {
          window.location.href = "/home";
          setLoggining(false);
        }, 1500);
        alertify.success("Giriş yapılıyor..", 1.5);
      } catch (error) {
        alertify.error("Giriş yapılamadı..", 1.5);
        setLoggining(false);
      }
    } else {
      alertify.error("Giriş yapılamadı..", 1.5);
      setLoggining(false);
    }
  };

  return (
    <div className="login_container">
      {" "}
      <MDBContainer>
        <MDBRow className="d-flex justify-content-center align-center-div">
          <MDBCol className="col-lg-5 col-md-10 col-sm-10">
            <div className="d-flex justify-content-center flex-column">
           <div className="d-flex justify-content-center">  <img className="company-logo" src={logo}></img></div>
              
             

              <MDBInput
                wrapperClass="mt-4"
                label="Kullanıcı Adı"
                id="name"
                name="name"
                placeholder="Lütfen kullanıcı adınızı giriniz..."
                type="text"
                valid={validation.name === "T"}
                invalid={validation.name === "F"}
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={user.name}
              />
           
              <MDBInput
                wrapperClass="mb-4"
                label="Şifre"
                id="password"
                name="password"
                placeholder="Lütfen şifreniz giriniz..."
                type="password"
                valid={validation.password === "T"}
                invalid={validation.password === "F"}
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={user.password}
              />
              <Button
                color="dark"
                className="w-100 mb-4"
                size="md"
                type="submit"
                onClick={(e) => onSubmitHandler(e)}
              >
                {loggining === true ? <Indicator /> : "Giriş Yap"}
              </Button>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

export default Login;
