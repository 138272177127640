import React, { useState, useEffect } from "react";
import { Container, Card, CardTitle, CardBody, Row, Table } from "reactstrap";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FormApi } from "../../api/form_api";
import ReactHtmlParser from "react-html-parser";
import Layout from "../../Layout";
import Paginator from "../../Paginator";
import './career.css'; 

const endpoint = "career";

const Career = () => {
  const formApi = new FormApi(endpoint);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);

  const goEditProduct = (product) => {
    navigate("/form-career", { state: { product } });
  };

  const deleteProduct = async (e, product) => {
    e.preventDefault();

    alertify.confirm(
      "Silme İşlemi",
      `Silmek istediğinizden emin misiniz?`,
      async function () {
        try {
          const obj = { _id: product._id };
          const result = await formApi.deletePage(obj);
          if (result) {
            alertify.success("İlgili alan silindi..", 2.5);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        } catch (e) {
          alertify.error("İlgili alan silinemedi..", 2.5);
        }
      },
      function () {
        alertify.error("İşlemden vazgeçildi...", 20);
      }
    );
  };

  const loadPage = (products) => {
    return products.map((product) => (
      <React.Fragment key={product.jobs_no}>
        <tr className="align-middle text-center">
          <td rowSpan={product.jobs.length || 1}>
            <img src={`https://idare.konakglobal.com/uploads/${product.img}`} alt="Career Banner" height={50} />
          </td>
          <td className="align-middle" rowSpan={product.jobs.length || 1}>
            {product.title_tr ? ReactHtmlParser(product.title_tr.substring(0, 100)) : "İçerik bulunamadı."}
          </td>
          <td className="align-middle" rowSpan={product.jobs.length || 1}>
            {product.title_en ? ReactHtmlParser(product.title_en.substring(0, 100)) : "Content not found."}
          </td>
          <td className="align-middle" rowSpan={product.jobs.length || 1}>
            {product.desc_tr ? ReactHtmlParser(product.desc_tr.substring(0, 100)) : "İçerik bulunamadı."}
          </td>
          <td className="align-middle" rowSpan={product.jobs.length || 1}>
            {product.desc_en ? ReactHtmlParser(product.desc_en.substring(0, 100)) : "Content not found."}
          </td>
          <td className="align-middle" rowSpan={product.jobs.length || 1}>
            {product.openjobs_title_tr ? ReactHtmlParser(product.openjobs_title_tr.substring(0, 100)) : "İçerik bulunamadı."}
          </td>
          <td className="align-middle" rowSpan={product.jobs.length || 1}>
            {product.openjobs_title_en ? ReactHtmlParser(product.openjobs_title_en.substring(0, 100)) : "Content not found."}
          </td>
  
          {product.jobs && product.jobs.length > 0 ? (
            <>
              <td className="align-middle">
                {product.jobs[0].jobs_name_tr ? ReactHtmlParser(product.jobs[0].jobs_name_tr.substring(0, 100)) : "İçerik bulunamadı."}
              </td>
              <td className="align-middle">
                {product.jobs[0].jobs_name_en ? ReactHtmlParser(product.jobs[0].jobs_name_en.substring(0, 100)) : "Content not found."}
              </td>
              <td className="align-middle">
                {product.jobs[0].jobs_desc_tr ? ReactHtmlParser(product.jobs[0].jobs_desc_tr.substring(0, 100)) : "İçerik bulunamadı."}
              </td>
              <td className="align-middle">
                {product.jobs[0].jobs_desc_en ? ReactHtmlParser(product.jobs[0].jobs_desc_en.substring(0, 100)) : "Content not found."}
              </td>
              <td className="option" rowSpan={product.jobs.length || 1}>
                <BiEdit onClick={(e) => goEditProduct(product)} />
              </td>
            </>
          ) : (
            <>
              <td className="align-middle" colSpan={4}>
                "İçerik bulunamadı."
              </td>
            </>
          )}
        </tr>
  
        {product.jobs.slice(1).map((job, index) => (
          <tr className="align-middle text-center" key={index}>
            <td className="align-middle">
              {job.jobs_name_tr ? ReactHtmlParser(job.jobs_name_tr.substring(0, 100)) : "İçerik bulunamadı."}
            </td>
            <td className="align-middle">
              {job.jobs_name_en ? ReactHtmlParser(job.jobs_name_en.substring(0, 100)) : "Content not found."}
            </td>
            <td className="align-middle">
              {job.jobs_desc_tr ? ReactHtmlParser(job.jobs_desc_tr.substring(0, 100)) : "İçerik bulunamadı."}
            </td>
            <td className="align-middle">
              {job.jobs_desc_en ? ReactHtmlParser(job.jobs_desc_en.substring(0, 100)) : "Content not found."}
            </td>
          </tr>
        ))}
      </React.Fragment>
    ));
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await formApi.getPage();
      setProducts(res);
      setTempProducts(loadPage(res));
    };
    fetchData();
  }, []);

  return (
    <Layout>
      <main className="home">
        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle><b>Kariyer</b></CardTitle>
                  <br />
                  <div className="p-3">
                    <div className="d-lg-flex d-md-flex justify-content-between p-3">
                      {/* <Button className="bg-black" onClick={() => navigate("/form-about")}>Hakkımızda Ekle</Button> */}
                    </div>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="table-scroll col-12">
                          <div className="table-responsive">
                            <Table striped bordered hover>
                              <thead>
                                <tr className="text-bold text-center">
                                  {["Kariyer Banner", "Başlık(tr)", "Başlık (en)", "Açıklama (tr)", "Açıklama (en)", "Açık Pozisyonlar (tr)", "Açık Pozisyonlar (en)", "İş İsmi (tr)", "İş İsmi(en)", "İş Açıklaması(tr)", "İş Açıklaması(en)", "Seçenekler"].map((header) => (
                                    <th key={header}>{header}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {tempProducts.length === 0 ? (
                                  <tr>
                                    <td colSpan="12" className="text-danger p-2 text-center">Kariyer içeriği bulunamadı.</td>
                                  </tr>
                                ) : (
                                  tempProducts.slice(0, 10)
                                )}
                              </tbody>
                            </Table>
                          </div>
                          {products.length > 0 && (
                            <Paginator len={products.length} data={products} setTempProducts={setTempProducts} loadPage={loadPage} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default Career;
