import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardTitle,
  CardBody,
  Row,
  Button,
  Table,
  Input,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FormApi } from "../../api/form_api";
import ReactHtmlParser from "react-html-parser";
import Layout from "../../Layout";
import logo1 from "../../assets/osi_logo_white.webp";
import logo2 from "../../assets/logo.png";
import logo3 from "../../assets/osi_logo_black.webp";
import ThemeSwitcher from "../../components/ThemeSwitcher";
import { useSelector } from "react-redux";
import Footer from "../../Layout/Footer";
import Homepage from "./Homepage";
const HomepageMain = () => {


  return (
    <Layout>
      <main className="home">
        <Container>
          <div className="h1">Anasayfa</div>
        </Container>
<Homepage/>
       <Footer/>
      </main>
    </Layout>
  );
};

export default HomepageMain;